import Button from "components/Button";
import Card from "components/Card";
import Header from "components/Header";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import { useNavigate } from "react-router";
import "./Access.scss";

function Access() {

	const user = useSelector(store=>store.UserReducer.data);
	const navigate = useNavigate();
	useEffect(()=>{
		if(user?.status === "active"){
			navigate("/dashboard");
		}
		if(user?.status === "lite"){
			navigate("/access");
		}
	},[user]);

	const handleClick = () => {
		if(process.env.REACT_APP_STORE_URL){
			window.location.href = process.env.REACT_APP_STORE_URL;
		}
	}

	return (
		<div className="Access">
			<Card className="accessInfo">

				<Card.Title>
					<Header
						title="
						
						Estamos encantados de que estes aqui!
						"
					/>
				</Card.Title>

				<Card.Content centered>

		

					<p className="info">
						Para poder acceder a tu dashboard es necesario que hayas completado almenos una compra en nuestra tienda. 
					</p>

					<div className="actionGroup">
				
					</div>

				</Card.Content>

				<Card.Controls borderless>
					{process.env.REACT_APP_STORE_URL &&
						<Button action={handleClick}>
							Comprar ahora
						</Button>
					}
				</Card.Controls>
			</Card>
		</div>
	);
}

export default Access;